import React from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import { landingPageNews } from 'src/constants';
import NextIcon from 'icons/next.svg';

const News = () => {
  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: 'news-slider',
    centerMode: true,
    centerPadding: '32px',
    nextArrow: (
      <div>
        <NextIcon alt='Next' data-category='news_right' data-action='News Slide' data-label='-' />
      </div>
    ),
    prevArrow: (
      <div>
        <NextIcon
          alt='Prev'
          className='transform rotate-180'
          data-category='news_left'
          data-action='News Slide'
          data-label='-'
        />
      </div>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='pt-12 pb-12 mb-24 lg:mb-0 lg:pb-24' data-testid='news-section'>
      <div className='container px-4 mx-auto'>
        <div className='mb-4 text-center lg:mb-8'>
          <h1 className='leading-8 app-heading-main text-primary'>Easiloan in News</h1>
        </div>
        <Slider {...settings}>
          {landingPageNews.map((landingPageNew) => (
            <React.Fragment key={landingPageNew.title}>
              <a
                href={landingPageNew.link}
                target='_blank'
                rel='noreferrer'
                className='inline-flex items-center h-40 p-4 m-2 space-x-4 bg-white lg:m-4 shadow-card-shadow-secondary rounded-xl'
                data-category='news_clicked'
                data-action='news click'
                data-label='-'
              >
                <div>
                  <Image
                    layout='fixed'
                    objectFit='contain'
                    objectPosition='left'
                    width='80px'
                    height='80px'
                    src={landingPageNew.logoUrl}
                    alt={landingPageNew.title}
                  />
                </div>
                <div data-testid={`news-${landingPageNew.id}`}>
                  <h4 className='uppercase app-text-link text-secondary'>{landingPageNew.title}</h4>
                  <p className='mt-1 app-text-dm-xs text-gray opacity-60'>{landingPageNew.description}</p>
                </div>
              </a>
            </React.Fragment>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default News;
