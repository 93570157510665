import React from 'react';
import Link from 'next/link';
// Icons
import UnLockIcon from 'icons/unlock.svg';
import { LANDING_PAGE } from 'src/formMapper';

const TopSection = () => {
  return (
    <div className='relative z-10 bg-floral' data-testid='top-section'>
      <div className='container relative px-4 pt-4 pb-0 mx-auto'>
        <div className='absolute top-0 left-0 right-0 z-0 hidden w-screen h-full bg-light-100 ml-50per-50vw mr-50per-50vw' />
        <div className='grid items-center gap-0 pt-4 pb-4 lg:grid-cols-12 lg:pt-2 lg:pb-5'>
          <div className='col-span-5 px-0 lg:pl-[70px] pt-0 pb-3 lg:pb-0'>
            <h1 className='app-heading-big text-primary leading-tight lg:leading-[54px] whitespace-nowrap'>
              {LANDING_PAGE.header.subText_header} <br />
              {LANDING_PAGE.header.remainingText_header}
            </h1>
            <h2 className='app-heading-primary  text-secondary leading-none lg:leading-tight mt-[6px]'>
              {LANDING_PAGE.subHeader.subHeader_text}
            </h2>
            <p className='mt-3 app-text-dm-small text-gray opacity-80'>{LANDING_PAGE.helpText.topSection_godigital}</p>
            <Link href='/loan-requirement'>
              <button
                type='button'
                className='mt-5 app-btn app-btn-primary'
                data-testid='unlock-loan-offers'
                data-category='unlock_loan'
                data-action='Unlock Loan offers'
                data-label='-'
              >
                <UnLockIcon /> <span>{LANDING_PAGE.button.button_capital}</span>
              </button>
            </Link>
          </div>
          <div className='col-span-7'>
            <img src='/icons/website-banner-illustration.svg' alt='' className='lg:h-[480px] ml-auto' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
