import { VALIDATION_MESSAGE } from 'src/formMapper';
import { transformNullToString } from 'src/helper';
import * as yup from 'yup';

export const validationSchema = yup
  .object()
  .shape({
    loan_type: yup
      .number()
      .transform((value, originalValue) => {
        const val = transformNullToString(originalValue);
        return val === '' ? undefined : Number(val);
      })
      .required(VALIDATION_MESSAGE.selectLoanType),

    loan_amount_needed: yup
      .number()
      .transform((value, originalValue) => {
        const val = transformNullToString(originalValue);
        return val === '' ? undefined : Number(val.replace(/, /g, ''));
      })
      .required(VALIDATION_MESSAGE.loanAmountNeeded),

    preferred_loan_tenure: yup
      .number()
      .min(1)
      .max(30)
      .transform((value, originalValue) => {
        const val = transformNullToString(originalValue);
        return val === '' ? undefined : Number(val);
      })
      .required(VALIDATION_MESSAGE.preferredLoanTenure)
  })
  .defined();
