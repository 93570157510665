import React, { useState } from 'react';
// Components
// Icons
import HowItWorksIcon from 'icons/how-it-works.svg';
import ForCustomersIcon from 'icons/for-customers.svg';
import ForCustomersActiveIcon from 'icons/for-customers-active.svg';
import ForDevelopers from 'icons/for-developers.svg';
import ForDevelopersActive from 'icons/for-developers-active.svg';
import ForChannelPartnersIcon from 'icons/for-channel-partners.svg';
import ForChannelPartnersActiveIcon from 'icons/for-channel-partners-active.svg';
import DottedSmallIcon from 'icons/dotted-small.svg';
import PathIcon from 'icons/path.svg';
// Constants / Data
import { HOW_IT_WORKS } from 'src/constants';
import { LANDING_PAGE } from 'src/formMapper';


const HowItWorks = () => {
  const [howIteWorks, setHowIteWorks] = useState('customers');

  return (
    <div className='container px-4 mx-auto sm:px-4'>
      <div className='mb-5 text-center'>
        <div className='flex items-center justify-center mb-4 space-x-2 uppercase text-primary leading-0'>
          <HowItWorksIcon />
          <span className='app-text-link'>{LANDING_PAGE.subHeader.how_it_works_text}</span>
        </div>
        <h1 className='app-heading-main leading-10 lg:leading-8 text-primary'>{LANDING_PAGE.header.home_loan_made_easy_text}</h1>
      </div>
      <div className='relative mb-0 text-center lg:mb-6'>
        <div className='relative inline-flex'>
          <div className='relative z-10 flex items-center px-3 py-2 space-x-1 overflow-auto uppercase rounded-full bg-secondary w-screen-full-except-32 md:w-full'>
            <button
              className={`${howIteWorks === 'customers' ? 'tab-with-bg-active' : 'tab-with-bg'}`}
              onClick={() => setHowIteWorks('customers')}
              data-testid='customers-how-it-works'
            >
              {howIteWorks === 'customers' ? <ForCustomersActiveIcon /> : <ForCustomersIcon />}
              <span>{LANDING_PAGE.button.button_get_in_touch_for_cus}</span>
            </button>
            <button
              className={`${howIteWorks === 'developers' ? 'tab-with-bg-active' : 'tab-with-bg'}`}
              onClick={() => setHowIteWorks('developers')}
              data-testid='developers-how-it-works'
            >
              {howIteWorks === 'developers' ? <ForChannelPartnersActiveIcon /> : <ForChannelPartnersIcon />}
              <span>{LANDING_PAGE.button.button_get_in_touch_for_dev}</span>
            </button>
            <button
              className={`${howIteWorks === 'partners' ? 'tab-with-bg-active' : 'tab-with-bg'}`}
              onClick={() => setHowIteWorks('partners')}
              data-testid='partners-how-it-works'
            >
              {howIteWorks === 'partners' ? <ForDevelopersActive /> : <ForDevelopers />}
              <span>{LANDING_PAGE.button.button_get_in_touch_for_cp}</span>
            </button>
          </div>
          <div className='absolute z-0 hidden -right-6 -top-6 md:block'>
            <DottedSmallIcon />
          </div>
        </div>
      </div>
      {/* For Customers */}
      <ul
        className={`pt-6 lg:pt-8 grid grid-cols-1 gap-0 lg:gap-4 sm:grid-cols-4 works-grid ${
          howIteWorks === 'customers' ? 'grid' : 'hidden'
        }`}
        data-testid='customers-list'
      >
        {HOW_IT_WORKS.customers.map((customer, index) => (
          <li key={String(index)} className='relative flex flex-col col-span-1 text-center divide-y divide-gray-200'>
            <div className='flex flex-col flex-1 px-4 py-4'>
              <img className='flex-shrink-0 mx-auto md:h-180' src={customer.imageUrl} alt='' />
              <h3 className='mt-5 app-text-dm-large text-secondary whitespace-nowrap lg:mt-7'>{customer.title}</h3>
              <PathIcon className='absolute hidden -left-12 top-24 lg:block' />
              <dl className='flex flex-col justify-between flex-grow mt-[5px]'>
                <dt className='sr-only'>Description</dt>
                <dd className='w-11/12 mx-auto app-text-dm-small text-gray opacity-80'>{customer.description}</dd>
              </dl>
            </div>
          </li>
        ))}
      </ul>
      {/* For channel Partners */}
      <ul
        className={`pt-6 lg:pt-8  grid grid-cols-1 gap-4 sm:grid-cols-4 works-grid ${
          howIteWorks === 'partners' ? 'grid' : 'hidden'
        }`}
        data-testid='partners-list'
      >
        {HOW_IT_WORKS.channelPartners.map((customer, index) => (
          <li key={String(index)} className='relative flex flex-col col-span-1 text-center divide-y divide-gray-200'>
            <div className='flex flex-col flex-1 px-4 py-4'>
              <img className='flex-shrink-0 mx-auto md:h-180' src={customer.imageUrl} alt='' />
              <h3 className='mt-5 app-text-dm-large text-secondary whitespace-nowrap lg:mt-7'>{customer.title}</h3>
              <PathIcon className='absolute hidden -left-12 top-24 lg:block' />
              <dl className='flex flex-col justify-between flex-grow mt-[5px]'>
                <dt className='sr-only'>Description</dt>
                <dd className='w-11/12 mx-auto text-sm text-black-100 font-body opacity-80'>{customer.description}</dd>
              </dl>
            </div>
          </li>
        ))}
      </ul>
      {/* For Developers */}
      <ul
        className={`pt-6 lg:pt-8  grid grid-cols-1 gap-4 sm:grid-cols-4 works-grid ${
          howIteWorks === 'developers' ? 'grid' : 'hidden'
        }`}
        data-testid='developers-list'
      >
        {HOW_IT_WORKS.developers.map((customer, index) => (
          <li key={String(index)} className='relative flex flex-col col-span-1 text-center divide-y divide-gray-200'>
            <div className='flex flex-col flex-1 px-4 py-4'>
              <img className='flex-shrink-0 mx-auto md:h-180' src={customer.imageUrl} alt='' />
              <h3 className='mt-5 app-text-dm-large text-secondary whitespace-nowrap lg:mt-7'>{customer.title}</h3>
              <PathIcon className='absolute hidden -left-12 top-24 lg:block' />
              <dl className='flex flex-col justify-between flex-grow mt-[5px]'>
                <dt className='sr-only'>Description</dt>
                <dd className='w-11/12 mx-auto text-sm text-black-100 font-body opacity-80'>{customer.description}</dd>
              </dl>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HowItWorks;
