import React from 'react';
// Icons
import DottedSmallIcon from 'icons/dotted-small.svg';
import DottedBigIcon from 'icons/dotted-big.svg';
// Constants / Data
import { THREE_GRID_DATA } from 'src/constants';

const ThreeGrid = () => {
  return (
    <div className='relative py-12 lg:py-16' data-testid='three-grid-section'>
      <div className='container px-4 sm:px-[30px]  mx-auto'>
        <div className='z-10'>
          <ul className='relative grid grid-cols-1 gap-5 lg:gap-12 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3'>
            {THREE_GRID_DATA.map((ourProduct, index) => (
              <li key={String(index)} className='relative z-10'>
                <div className='relative z-10 flex flex-col col-span-1 text-center bg-white divide-y divide-gray-200 rounded-lg shadow-card-shadow-secondary'>
                  <div className='flex flex-col flex-1 p-4 lg:p-8'>
                    <img className='flex-shrink-0 mx-auto h-44' src={ourProduct.imageUrl} alt='' />
                    <h3 className='mt-6 app-text-dm-large text-secondary'>{ourProduct.name}</h3>
                    <dl className='flex flex-col justify-between flex-grow mt-1'>
                      <dt className='sr-only'>Description</dt>
                      <dd className='app-text-dm-small text-opacity-80 text-gray'>{ourProduct.description}</dd>
                    </dl>
                  </div>
                </div>
                {index === 1 && (
                  <div className='absolute z-0 hidden -right-24 -bottom-12 lg:block'>
                    <DottedBigIcon />
                  </div>
                )}
                {index === 2 && (
                  <div className='absolute z-0 hidden -right-7 -top-0 lg:block'>
                    <DottedSmallIcon />
                  </div>
                )}
              </li>
            ))}
            <div className='absolute z-0 hidden -left-8 -top-8 lg:block'>
              <DottedBigIcon />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ThreeGrid;
