import React from 'react';
import Link from 'next/link';
// Components
// Icons
import DottedSmallIcon from 'icons/dotted-small.svg';
import DottedBigIcon from 'icons/dotted-big.svg';
// Constants / Data
import { OUR_PRODUCTS } from 'src/constants';
import { LANDING_PAGE } from 'src/formMapper';

const LandingPage = () => {
  const onSelectLoanType = (id) => {
    localStorage.setItem('loanType', id);
  };

  return (
    <div className='container px-4 mx-auto bg-left-bottom bg-no-repeat sm:px-[30px]'>
      <div className='absolute top-0 right-0 hidden w-screen h-96 -left-14' />
      <div>
        <div className='mb-5 text-center lg:mb-20'>
          <h1 className='app-heading-big text-primary'>{LANDING_PAGE.header.our_product_text}</h1>
        </div>
        <ul className='relative grid grid-cols-1 gap-6 lg:gap-12 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3'>
          {OUR_PRODUCTS.map((ourProduct, index) => (
            <li key={String(index)} className='relative' data-testid={`our-products-${index}`}>
              <div className='relative z-10'>
                <div className='relative z-10 flex flex-col col-span-1 text-center bg-white rounded-lg shadow-app-card'>
                  <div className='flex flex-col flex-1 px-4 py-5 lg:px-10 lg:py-10'>
                    <img className='flex-shrink-0 mx-auto h-[200px]' src={ourProduct.imageUrl} alt={ourProduct.name} />
                    <h3 className='mt-1 app-text-dm-large text-secondary'>{ourProduct.name}</h3>
                    <dl className='flex flex-col justify-between flex-grow mt-1'>
                      <dt className='sr-only'>Description</dt>
                      <dd className='w-9/12 mx-auto text-sm text-gray opacity-80'>{ourProduct.description}</dd>
                    </dl>
                    <div>
                      <Link href='/loan-requirement'>
                        <a
                          role='button'
                          tabIndex={index}
                          className='mx-auto mt-8 app-btn app-btn-primary bg-primary'
                          onClick={() => onSelectLoanType(ourProduct.id)}
                        >
                          <img src='/icons/get-started-white.svg' />
                          <span>{ourProduct.buttonTitle}</span>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {index === 0 && (
                <div className='absolute z-0 hidden -right-8 -top-8 lg:block'>
                  <DottedSmallIcon />
                </div>
              )}
              {index === 1 && (
                <div className='absolute z-0 hidden -right-5 -bottom-5 lg:block'>
                  <DottedSmallIcon />
                </div>
              )}
            </li>
          ))}
          <div className='absolute z-0 hidden -right-8 -top-8 lg:block'>
            <DottedBigIcon />
          </div>
          <div className='absolute z-0 hidden -left-8 -bottom-8 lg:block'>
            <DottedBigIcon />
          </div>
        </ul>
      </div>
    </div>
  );
};

export default LandingPage;
