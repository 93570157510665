export const events = (type) => {
  switch (type) {
    case 'customers':
      return {
        'data-category': 'Query Form Filling',
        'data-action': 'Send Message - For Customers',
        'data-label': '/'
      };
    case 'developers':
      return {
        'data-category': 'Query Form Filling',
        'data-action': 'Send Message - For Developers',
        'data-label': '/'
      };
    case 'banks':
      return {
        'data-category': 'Query Form Filling',
        'data-action': 'Send Message - For Banks',
        'data-label': '/'
      };

    default: {
      return {};
    }
  }
};
