import React from 'react';
import PropTypes from 'prop-types';
import { events } from 'components/landingPage/contactUs/helper';

export const ContactUsBtn = React.memo(({ text, type, loading, onClick, hideTextOnLoading, className, formType }) => {
  return (
    <button
      type={type}
      disabled={loading}
      aria-disabled={loading}
      className={`flex items-center mt-8 app-btn app-btn-primary ${className}`}
      {...(type === 'submit' ? {} : (onClick && { onClick }) || {})}
      {...events(formType)}
    >
      {!loading && (
        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' viewBox='0 0 14 15'>
          <g fill='#FFF' fillRule='evenodd'>
            <g fill='#FFF' fillRule='nonzero' transform='translate(-8.000000, -8.000000)'>
              <path d='M21.069 14.552L9.615 8.138c-.193-.108-.414-.154-.634-.133-.22.021-.428.109-.596.251-.17.143-.29.334-.348.547-.057.213-.048.439.026.647l1.967 5.507h5.11c.143 0 .281.057.383.159.102.101.159.24.159.383 0 .144-.057.282-.159.384-.102.101-.24.159-.384.159h-5.11l-1.966 5.506c-.058.164-.076.34-.053.511.024.172.088.337.188.479.1.142.232.258.386.338.154.08.325.123.5.124.186 0 .369-.049.531-.14l11.454-6.414c.168-.094.308-.232.406-.398.097-.167.149-.356.149-.549 0-.193-.052-.382-.15-.549-.097-.166-.237-.303-.405-.398z' />
            </g>
          </g>
        </svg>
      )}

      {loading && (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-6 h-6 animate-ping'
          fill='none'
          viewBox='0 0 24 24'
          aria-hidden={!loading}
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z'
          />
        </svg>
      )}
      {hideTextOnLoading && loading ? '' : <span className='ml-2'>{text}</span>}
    </button>
  );
});

ContactUsBtn.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  hideTextOnLoading: PropTypes.bool,
  className: PropTypes.string,
  formType: PropTypes.string.isRequired
};

ContactUsBtn.defaultProps = {
  type: 'button',
  loading: false,
  onClick: undefined,
  hideTextOnLoading: false,
  className: ''
};
