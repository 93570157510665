import React, { useRef } from 'react';
// Components
import Header from 'components/header';
import Footer from 'components/footer';
import TopSection from 'src/components/landingPage/top-section';
import LoanRequirements from 'components/landingPage/loan-requirements';
import LoanOffers from 'src/components/landingPage/loan-offers';
import OurProducts from 'components/landingPage/our-products';
import HowItWorks from 'src/components/landingPage/how-it-works';
import AboutUs from 'src/components/landingPage/about-us';
import FourGrid from 'src/components/landingPage/four-grid';
import Testimonials from 'src/components/landingPage/testimonials';
import BankPartnetships from 'src/components/landingPage/bank-partnetships';
import ThreeGrid from 'src/components/landingPage/three-grid';
import GetInTouch from 'src/components/landingPage/get-in-touch';
import News from 'src/components/landingPage/news';
import { NAV_BAR } from 'src/formMapper';

// Icons
import HowItWorksIcon from 'icons/how-it-works.svg';
import ProductsIcon from 'icons/products.svg';
import GetStartedIcon from 'icons/get-started.svg';
import BankPartnershipsIcon from 'icons/bank-partnerships.svg';
import TestimonialsIcon from 'icons/testimonials.svg';
import AboutUsIcon from 'icons/about-us.svg';
import DottedSmallIcon from 'icons/dotted-small.svg';
import DottedBigIcon from 'icons/dotted-big.svg';
import GetInTouchIcon2 from 'icons/get-in-touch.svg';

const LandingPage = () => {
  const productRef = useRef();
  const howItWorksRef = useRef();
  const getStartedRef = useRef();
  const aboutUsRef = useRef();
  const bankPartnershipsRef = useRef();
  const testimonialsRef = useRef();
  const getInTouchRef = useRef();

  return (
    <div>
      <Header isFixed={false} />
      <div className='relative'>
        <TopSection />
        {/* Menu */}
        <div
          className='container sticky top-0 z-50 hidden px-4 mx-auto -mt-8 lg:-mt-12 md:block'
          data-testid='menu-section'
        >
          <div className='flex justify-start gap-8 px-4 py-6 overflow-auto bg-white lg:justify-around lg:py-10 shadow-card-shadow-secondary rounded-10 lg:gap-0'>
            <button
              className='inline-flex items-center flex-shrink-0 uppercase text-primary app-text-link focus:outline-none'
              onClick={() => window.scrollTo(0, productRef.current.offsetTop)}
              data-testid='product'
              data-category='loan_product'
              data-action='Products get started'
              data-label='-'
            >
              <ProductsIcon className='mr-2' />
              {NAV_BAR.button.button_products}
            </button>
            <button
              className='inline-flex items-center flex-shrink-0 uppercase text-primary app-text-link focus:outline-none'
              onClick={() => window.scrollTo(0, howItWorksRef.current.offsetTop)}
              data-category='process_flow'
              data-action='How it works'
              data-label='-'
            >
              <HowItWorksIcon className='mr-2' />
              {NAV_BAR.button.button_how_it_works}
            </button>
            <button
              className='inline-flex items-center flex-shrink-0 uppercase text-primary app-text-link focus:outline-none'
              onClick={() => window.scrollTo(0, getStartedRef.current.offsetTop)}
              data-category='get_started'
              data-action='Get Started'
              data-label='-'
            >
              <GetStartedIcon className='mr-2' />
              {NAV_BAR.button.button_get_started}
            </button>
            <button
              className='inline-flex items-center flex-shrink-0 uppercase text-primary app-text-link focus:outline-none'
              onClick={() => window.scrollTo(0, aboutUsRef.current.offsetTop)}
              data-category='about_us'
              data-action='About Us'
              data-label='-'
            >
              <AboutUsIcon className='mr-2' />
              {NAV_BAR.button.button_about_us}
            </button>
            <button
              className='inline-flex items-center flex-shrink-0 uppercase text-primary app-text-link focus:outline-none'
              onClick={() => window.scrollTo(0, bankPartnershipsRef.current.offsetTop)}
              data-category='bank_partnership'
              data-action='Bank Partnership'
              data-label='-'
            >
              <BankPartnershipsIcon className='mr-2' />
              {NAV_BAR.button.button_bank_partnership}
            </button>
            <button
              className='inline-flex items-center flex-shrink-0 uppercase text-primary app-text-link focus:outline-none'
              onClick={() => window.scrollTo(0, testimonialsRef.current.offsetTop + 220)}
              data-category='testimonials'
              data-action='Testimonials'
              data-label='-'
            >
              <TestimonialsIcon className='mr-2' />
              {NAV_BAR.button.button_testimonials}
            </button>
            <button
              className='inline-flex items-center flex-shrink-0 uppercase text-primary app-text-link focus:outline-none'
              onClick={() => window.scrollTo(0, getInTouchRef.current.offsetTop)}
              data-category='Query Form Filling'
              data-action='Get In Touch'
              data-label='/'
            >
              <GetInTouchIcon2 className='mr-2' />
              {NAV_BAR.button.button_get_in_touch}
            </button>
          </div>
        </div>
        <LoanOffers />
        <div
          ref={productRef}
          id='products'
          className='relative py-2 bg-top bg-no-repeat bg-contain lg:py-20 bg-our-product'
          data-testid='our-products-section'
        >
          <OurProducts />
        </div>
        <div ref={howItWorksRef} id='how-it-works' className='py-10 lg:py-16' data-testid='how-it-works-section'>
          <HowItWorks />
        </div>
        <div ref={getStartedRef} id='get-started' className='relative overflow-hidden bg-light-yellow-to-white'>
          <div className='container relative py-10 pl-4 pr-4 mx-auto lg:py-20 sm:pl-24 sm:pr-48'>
            <DottedSmallIcon className='absolute -top-10 -left-10' />
            <div className='mb-0 lg:mb-4'>
              <h1 className='app-heading-big text-primary leading-10 md:leading-[56px]'>
                {NAV_BAR.header.header_text} <br />
                {NAV_BAR.header.header_rem_text}
              </h1>
            </div>
            <div className='relative mt-9'>
              <LoanRequirements />
            </div>
          </div>
          <DottedBigIcon className='absolute -bottom-0 -right-28' />
        </div>
        <div ref={aboutUsRef} id='about-us' className='pt-2 pb-0 lg:pt-8 md:pb-12' data-testid='about-us-section'>
          <AboutUs />
        </div>
        <FourGrid />
        <div ref={bankPartnershipsRef} id='bank-partnerships' data-testid='bank-partnership-section'>
          <BankPartnetships />
        </div>
        <ThreeGrid />
        <div
          ref={testimonialsRef}
          id='testimonials'
          className='bg-yellow-to-white lg:mt-[-265px] pt-2 lg:pt-[320px] pb-12 lg:pb-20'
        >
          <Testimonials />
        </div>
        <News />
        <div ref={getInTouchRef} id='get-in-touch' />
        <GetInTouch />
        {/* Footer */}
        <Footer
          onClickProduct={(height) => window.scrollTo(0, productRef.current.offsetTop + height)}
          onClickHowItWorks={(height) => window.scrollTo(0, howItWorksRef.current.offsetTop + height)}
          onClickGetStarted={(height) => window.scrollTo(0, getStartedRef.current.offsetTop + height)}
          onClickAboutUs={(height) => window.scrollTo(0, aboutUsRef.current.offsetTop + height)}
          onClickBankPartnerships={(height) => window.scrollTo(0, bankPartnershipsRef.current.offsetTop + height)}
          onClickTestimonials={(height) => window.scrollTo(0, testimonialsRef.current.offsetTop + height)}
          onClickGetInTouch={(height) => window.scrollTo(0, getInTouchRef.current.offsetTop + height)}
        />
      </div>
    </div>
  );
};

export default LandingPage;
