import React, { useState } from 'react';
// Components
import ContactUsForm from 'src/components/landingPage/contactUs';
// Icons
import ForCustomersIcon from 'icons/for-customers.svg';
import ForCustomersActiveIcon from 'icons/for-customers-active.svg';
import ForDevelopers from 'icons/for-developers.svg';
import ForDevelopersActive from 'icons/for-developers-active.svg';
import ForChannelPartnersIcon from 'icons/for-channel-partners.svg';
import ForChannelPartnersActiveIcon from 'icons/for-channel-partners-active.svg';
import DottedSmallIcon from 'icons/dotted-small.svg';
import DottedBigIcon from 'icons/dotted-big.svg';
import GetInTouchIcon from 'icons/get-in-touch-white.svg';
// Constants / Data
import { CONTACT_FORM_TYPE } from 'src/constants';
import { LANDING_PAGE } from 'src/formMapper';

const GetInTouch = () => {
  const [contactUsForm, setContactUsForm] = useState('customers');

  return (
    <div className='container relative mx-auto mt-10 lg:mt-[180px] mb-8 lg:-mb-10' data-testid='get-in-touch-section'>
      <div className='relative z-20 px-4 py-12 pb-4 lg:px-24 lg:py-16 bg-secondary rounded-4xl'>
        <div className='relative z-10 grid items-center grid-cols-12'>
          <div className='col-span-12 -mt-12 lg:col-span-5 lg:mt-0'>
            <div className='flex items-center mb-4'>
              <GetInTouchIcon />{' '}
              <span className='ml-2 text-white uppercase app-text-link'>{LANDING_PAGE.header.get_in_touch_text}</span>
            </div>
            <h1 className='mb-3 leading-10 text-white app-heading-main'>
              {LANDING_PAGE.header.get_in_touch_header_text}
            </h1>
            <h2 className='mb-2 text-white app-text-dm-small leading-tite'>
              {LANDING_PAGE.header.get_in_touch_header_contact_text}
              <br />
              {LANDING_PAGE.header.get_in_touch_header_contact_text_rem}
            </h2>
          </div>
          <div className='relative col-span-12 -mt-48 lg:col-span-7 rounded-4xl'>
            <div className='relative z-10 ml-auto bg-white rounded-4xl shadow-card-shadow-secondary md:max-w-xl'>
              <div className='inline-flex items-center justify-between w-full px-2 py-2 space-x-1 overflow-auto uppercase rounded-full bg-secondary'>
                <button
                  className={`${contactUsForm === 'customers' ? 'tab-with-bg-active' : 'tab-with-bg'} px-6 text-sm`}
                  onClick={() => setContactUsForm('customers')}
                  data-testid='customers'
                >
                  {contactUsForm === 'customers' ? <ForCustomersActiveIcon /> : <ForCustomersIcon />}
                  <span>{LANDING_PAGE.button.button_get_in_touch_for_cus}</span>
                </button>
                <button
                  className={`${contactUsForm === 'developers' ? 'tab-with-bg-active' : 'tab-with-bg'} px-6 text-sm`}
                  onClick={() => setContactUsForm('developers')}
                  data-testid='developers'
                >
                  {contactUsForm === 'developers' ? <ForDevelopersActive /> : <ForDevelopers />}
                  <span>{LANDING_PAGE.button.button_get_in_touch_for_dev}</span>
                </button>
                <button
                  className={`${contactUsForm === 'banks' ? 'tab-with-bg-active' : 'tab-with-bg'} px-6 text-sm`}
                  onClick={() => setContactUsForm('banks')}
                  data-testid='banks'
                >
                  {contactUsForm === 'banks' ? <ForChannelPartnersActiveIcon /> : <ForChannelPartnersIcon />}
                  <span>{LANDING_PAGE.button.button_get_in_touch_for_banks}</span>
                </button>
              </div>
              <div className='p-4 pt-0 md:p-9'>
                {CONTACT_FORM_TYPE.filter((c) => contactUsForm === c).map((contact) => (
                  <ContactUsForm key={contact} initialValues={{ type: contact }} />
                ))}
              </div>
            </div>
            <div className='absolute z-0 hidden -left-9 top-16 sm:block'>
              <DottedBigIcon />
            </div>
          </div>
          <div className='absolute z-10 hidden -right-7 -bottom-6 sm:block'>
            <DottedSmallIcon />
          </div>
        </div>
      </div>
      <div className='absolute z-0 hidden -left-8 top-28 sm:block'>
        <DottedSmallIcon />
      </div>
      <div className='absolute z-0 hidden opacity-50 left-48 -bottom-8 sm:block'>
        <DottedBigIcon />
      </div>
    </div>
  );
};

export default GetInTouch;
