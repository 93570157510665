import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'src/helper';
import useInteractiveStates from 'src/hooks/interactiveStates';

export const Input = React.forwardRef(
  ({ name, value, error, errorMessage, inWords, register, iconName, className, ...rest }) => {
    const { lostFocus, onBlur, onFocus } = useInteractiveStates();
    return (
      <div className='relative rounded-md' role='presentation'>
        <input
          className={`block w-full px-5 py-[15px] ${
            error && lostFocus ? 'border border-red text-red' : (value && lostFocus && 'border') || ''
          } shadow-none disabled:bg-disabled ${className}`}
          aria-invalid={error}
          aria-describedby={`${name}-error`}
          {...(register ? register(name) : {})}
          {...(register ? {} : { value })}
          {...rest}
          onFocus={onFocus}
          onBlur={onBlur}
          data-testid={`${name}`}
        />
        {!isEmpty(inWords) && !lostFocus && (
          <p className='mt-[5px] app-text-dm-xxs text-right text-gray text-opacity-60 absolute right-0 truncate w-full'>
            {inWords}
          </p>
        )}
      </div>
    );
  }
);

Input.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  inWords: PropTypes.string,
  register: PropTypes.func,
  iconName: PropTypes.string,
  className: PropTypes.string
};

Input.defaultProps = {
  error: undefined,
  value: undefined,
  errorMessage: '',
  inWords: '',
  register: undefined,
  iconName: undefined,
  className: ''
};
