import React from 'react';
import Slider from 'react-slick';
// Components
// Icons
import QuoteIcon from 'icons/quote-or.svg';
import DottedSmallIcon from 'icons/dotted-small.svg';
import DottedBigIcon from 'icons/dotted-big.svg';
import QuoteSecondaryIcon from 'icons/quote-secondary.svg';
import NextIcon from 'icons/next.svg';
// Constants / Data
import { TESTIMONIALS } from 'src/constants';
import { LANDING_PAGE } from 'src/formMapper';

const Testimonials = () => {
  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '70px',
    className: 'testimonial-slider',
    nextArrow: (
      <div>
        <NextIcon alt='Next' data-category='testimonial_right' data-action='Testimonials slide' data-label='-' />
      </div>
    ),
    prevArrow: (
      <div>
        <NextIcon
          alt='Prev'
          data-category='testimonial_left'
          data-action='Testimonials slide'
          data-label='-'
          className='transform rotate-180'
        />
      </div>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '32px'
        }
      }
    ]
  };

  return (
    <div className='container relative px-4 lg:px-[30px] ml-auto mr-auto'>
      <div>
        <div className='mb-2 lg:mb-10 relative z-10 lg:pl-[5.5rem]'>
          <div className='flex items-center mb-3 space-x-2 uppercase app-text-link text-primary leading-0'>
            <QuoteIcon />
            <span>{LANDING_PAGE.header.testimonials_text}</span>
          </div>
          <h1 className='leading-tight app-heading-big lg:leading-56 text-primary'>
            {LANDING_PAGE.header.testimonials_text_header}
            <br /> {LANDING_PAGE.header.testimonials_text_header_rem}
          </h1>
        </div>
        <div className='relative grid grid-cols-12 gap-2 lg:pl-12'>
          <div className='absolute z-0 hidden right-20 -top-6 lg:block'>
            <DottedSmallIcon />
          </div>
          <div className='absolute left-[120px] bottom-2 z-0 hidden lg:block'>
            <DottedBigIcon />
          </div>
          <div className='hidden col-span-1 lg:block'>
            <QuoteSecondaryIcon />
          </div>
          <div className='relative col-span-12 lg:col-span-11'>
            <div className='pl-0 -mx-4 lg:pl-8 lg:mx-0' data-testid='testimonials-section'>
              <div className='absolute top-0 bottom-0 right-0 z-10 hidden w-12 h-full bg-testimonial-slider-overlay lg:block' />
              <Slider {...settings}>
                {TESTIMONIALS.map((testimonial, index) => (
                  <div className='px-2 py-4 lg:px-5' key={String(index)}>
                    <div className='flex flex-col px-6 py-5 bg-white rounded-lg lg:pl-10 lg:pr-12 lg:py-10 shadow-app-card'>
                      <div className='flex items-center space-x-1'>
                        <img className='flex-shrink-0 h-5 rounded-full' src='/icons/star-yellow.svg' alt='' />
                        <img className='flex-shrink-0 h-5 rounded-full' src='/icons/star-yellow.svg' alt='' />
                        <img className='flex-shrink-0 h-5 rounded-full' src='/icons/star-yellow.svg' alt='' />
                        <img className='flex-shrink-0 h-5 rounded-full' src='/icons/star-yellow.svg' alt='' />
                        <img className='flex-shrink-0 h-5 rounded-full' src='/icons/star-yellow.svg' alt='' />
                      </div>
                      <div className='mt-3 mb-4'>
                        <h3 className='app-text-dm-xs text-gray opacity-60'>{testimonial.feedback}</h3>
                      </div>
                      <div className='flex items-center'>
                        <div className='flex items-center justify-center flex-shrink-0 w-10 h-10 mr-4 rounded-full bg-flyout'>
                          <div className='text-lg font-bold text-white'>{testimonial.name.slice(0, 1)}</div>
                        </div>
                        <div>
                          <h4 className='uppercase app-text-link text-primary'>{testimonial.name}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
