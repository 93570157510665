import React from 'react';
import PropTypes from 'prop-types';

export const PrimaryButton = React.memo(
  ({ type, disabled, onClick, children, dataTestId, eventCategory, eventLabel, eventAction }) => {
    return (
      <button
        type={type}
        disabled={disabled}
        aria-disabled={disabled}
        className='space-x-2 app-btn app-btn-primary group hover:-translate-y-1'
        {...(type === 'submit' ? {} : (onClick && { onClick }) || {})}
        {...(dataTestId !== '' ? { 'data-testid': dataTestId } : {})}
        {...(eventCategory
          ? { 'data-category': eventCategory, 'data-action': eventAction, 'data-label': eventLabel }
          : {})}
      >
        {children}
      </button>
    );
  }
);

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  eventCategory: PropTypes.string,
  eventLabel: PropTypes.string,
  eventAction: PropTypes.string
};

PrimaryButton.defaultProps = {
  type: 'button',
  onClick: undefined,
  disabled: false,
  dataTestId: '',
  eventCategory: undefined,
  eventLabel: undefined,
  eventAction: undefined
};
