import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toINRWords } from 'src/helper';
import { LOAN_TYPES } from 'components/loanRequirement/constants';
import { validationSchema } from 'components/landingPage/validation';
import { PrimaryButton } from 'components/atoms/primary-button';
import InputRange from 'components/molecules/v2/input-range';
import UnLockIcon from 'icons/unlock.svg';
import { useRouter } from 'next/router';
import InputWithLabel from 'components/molecules/input-with-label';
import DottedSmallIcon from 'icons/dotted-small.svg';
import { HOME_LOAN_REQUIREMENT, LANDING_PAGE } from 'src/formMapper';

const LoanRequirements = () => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { isSubmitting, errors }
  } = useForm({
    shouldFocusError: false,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const loanAmountNeeded = watch('loan_amount_needed');
  const loanType = watch('loan_type');
  const router = useRouter();

  const onFormSubmit = async (values) => {
    localStorage.setItem('loanData', JSON.stringify(values));
    router.push('/loan-requirement');
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className='grid grid-cols-6 mt-2 space-y-5 gap-x-2 lg:gap-x-8 lg:w-7/12'>
        {/* loan type options */}
        <InputWithLabel
          label={HOME_LOAN_REQUIREMENT.loan_type.label}
          name='loan_type'
          error={!!errors.loan_type}
          errorMessage={errors?.loan_type?.message}
          className='col-span-6 lg:col-span-4'
        >
          <div className='relative flex items-center gap-x-6'>
            {LOAN_TYPES.map((option, index) => (
              <Fragment key={String(option.id)}>
                <button
                  key={String(option.id)}
                  type='button'
                  className={`bg-white relative z-10 flex-col h-[106px] w-[106px] shadow-app-card border border-transparent btn btn-input hover:border-green ${
                    loanType === option.id ? 'border-green text-secondary' : ''
                  }`}
                  onClick={() => setValue('loan_type', option.id, { shouldValidate: true })}
                  data-testid={`loanType-${option.id}`}
                >
                  <div className='flex items-center h-9'>
                    <img src={option.src} alt={option.name} className='h-9' />
                  </div>
                  <div className='px-2 mt-2 uppercase font-medium text-[11px] font-title leading-[14px] text-gray text-opacity-60'>
                    {option.name}
                  </div>
                  {loanType === option.id && (
                    <div className='absolute inline-flex items-center overflow-hidden rounded-full opacity-100 -bottom-3 -right-3 text-green'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='bg-white'
                        width='32'
                        height='32'
                        viewBox='0 0 18 18'
                      >
                        <g fill='currentColor' fillRule='evenodd'>
                          <g fill='currentColor' fillRule='nonzero'>
                            <path
                              d='M24.996 16c4.968.011 8.993 4.034 9.004 9 0 2.695-1.209 5.248-3.292 6.957-2.084 1.71-4.824 2.396-7.468 1.87-4.538-.906-7.65-5.104-7.196-9.708.453-4.604 4.324-8.115 8.952-8.119zm4.722 6.386c-.304-.304-.796-.304-1.1 0L24.503 26.5l-2.042-2.042c-.304-.303-.796-.303-1.1 0-.303.304-.303.796 0 1.1l2.592 2.59c.304.304.796.304 1.1 0l4.665-4.663c.304-.304.304-.796 0-1.1z'
                              transform='translate(-16 -16)'
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  )}
                </button>
                {index === 2 && <DottedSmallIcon className='absolute top-5 right-[-16%] hidden lg:block z-0' />}
              </Fragment>
            ))}
          </div>
          <input className='sr-only' hidden aria-hidden='true' {...register('loan_type')} />
        </InputWithLabel>
        <div />
        <InputWithLabel
          label={HOME_LOAN_REQUIREMENT.loan_amount_needed.label}
          name='loan_amount_needed'
          error={!!errors.loan_amount_needed}
          errorMessage={errors?.loan_amount_needed?.message}
          className='col-span-6 lg:col-span-3'
        >
          <InputRange
            control={control}
            name='loan_amount_needed'
            placeholder={HOME_LOAN_REQUIREMENT.loan_amount_needed.placeholder}
            inWords={loanAmountNeeded && toINRWords(loanAmountNeeded)}
            min={1}
            max={60000000}
            step={100000}
          />
        </InputWithLabel>
        <InputWithLabel
          label={HOME_LOAN_REQUIREMENT.preferred_loan_tenure.label}
          name='preferred_loan_tenure'
          error={!!errors.preferred_loan_tenure}
          errorMessage={errors?.preferred_loan_tenure?.message}
          className='col-span-6 lg:col-span-3'
        >
          <InputRange
            control={control}
            name='preferred_loan_tenure'
            placeholder={HOME_LOAN_REQUIREMENT.preferred_loan_tenure.placeholder}
            min={1}
            max={30}
            step={1}
          />
        </InputWithLabel>
      </div>
      <div className='mt-8'>
        <PrimaryButton
          type='submit'
          loading={isSubmitting}
          dataTestId='loan-requirement-submit'
          eventCategory='unlock_loan_2'
          eventAction='Unlock Loan offers 2'
          eventLabel='-'
        >
          <UnLockIcon />
          <span>{LANDING_PAGE.button.button_unlock_loan_offer}</span>
        </PrimaryButton>
      </div>
    </form>
  );
};

LoanRequirements.propTypes = {};

export default LoanRequirements;
