import React from 'react';
// Icons
import DottedSmallIcon from 'icons/dotted-small.svg';
import DottedBigIcon from 'icons/dotted-big.svg';
import { HOME_LOAN_FEATURES } from 'src/constants';

const FourGrid = () => {
  return (
    <div className='py-8 overflow-hidden lg:py-12' data-testid='four-grid-section'>
      <div className='container relative mx-auto'>
        <div className='relative z-20 px-6 py-8 lg:px-24 lg:py-20 bg-secondary rounded-4xl '>
          <div className='mb-8 lg:mb-20 sm:w-9/12'>
            <h1 className='app-heading-main mb-3 leading-9 lg:leading-10 text-gray'>
              Easiloan platform allows Customers to apply
              <br /> for a Home Loan anytime, anywhere.
            </h1>
            <h2 className='mb-2 text-white app-text-dm-regular leading-tite'>
              Easiloan’s proprietary AI-based “Easiloan Instant Match” feature allows customers to compare and choose
              from shortlisted options based on their profile.
            </h2>
          </div>
          <dl className='space-y-10 lg:space-y-0 md:grid md:grid-cols-4 md:gap-10'>
            {HOME_LOAN_FEATURES.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className='flex items-end h-20 text-white lg:h-28'>
                    <img className='flex-shrink-0 h-full' src={feature.imageUrl} alt='' />
                  </div>
                  <p className='app-text-link mt-4 leading-6 uppercase text-gray'>{feature.name}</p>
                </dt>
                <dd className='mt-2 text-sm text-white app-text-dm-small'>{feature.description}</dd>
              </div>
            ))}
          </dl>
          <DottedSmallIcon className='absolute z-0 hidden -bottom-10 left-40 lg:block' />
          <DottedSmallIcon className='absolute z-0 hidden -top-10 right-40 lg:block' />
        </div>
        <DottedBigIcon className='absolute z-0 hidden -top-10 -left-10 lg:block' />
        <DottedBigIcon className='absolute z-0 top-[40%] -right-10 hidden lg:block' />
      </div>
    </div>
  );
};

export default FourGrid;
