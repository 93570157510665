import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Transition } from '@headlessui/react';
// import { CheckCircleIcon } from '@heroicons/react/outline';
// import { XIcon } from '@heroicons/react/solid';
import { ContactUsBtn } from 'src/components/atoms/contact-us-button';
import { Input } from 'components/molecules/v2/input';
import InputWithLabel from 'components/molecules/input-with-label';
import { validationSchema } from 'components/landingPage/contactUs/validation';
import { CONTACT_US, THANK_YOU } from 'src/formMapper';

const ContactUsForm = ({ initialValues }) => {
  const [showNotification, setNotification] = useState(false);
  const [showNotificationError, setNotificationError] = useState('');

  const {
    handleSubmit,
    register,
    watch,
    formState: { isSubmitting, errors },
    reset
  } = useForm({
    defaultValues: initialValues,
    shouldFocusError: false,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const type = watch('type');

  const onFormSubmit = async (values) => {
    axios.post(`${process.env.NEXT_PUBLIC_BASE_API_URL}/query/`, { ...values, product_type: 'Home Page' }).then(
      (response) => {
        if (response.data.id) {
          setNotification(true);
          window.dataLayer.push({ event: 'send_message' });
          reset();
        }
      },
      (error) => {
        setNotification(true);
        setNotificationError(error);
        setTimeout(() => {
          setNotification(false);
        }, 1500);
      }
    );
  };

  return (
    <>
      {(!showNotification || showNotificationError) && (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Input name='type' type='hidden' register={register} value={type} />
          <InputWithLabel
            label={CONTACT_US.name.label}
            name='name'
            error={!!errors.name}
            errorMessage={errors?.name?.message}
            className='block mt-8'
          >
            <div className='relative'>
              <Input
                name='name'
                type='text'
                placeholder={CONTACT_US.name.placeholder}
                error={!!errors.name}
                errorMessage={errors?.name?.message}
                register={register}
                value={watch('name')}
              />
            </div>
          </InputWithLabel>

          <div className='grid grid-cols-2 mt-4 gap-9'>
            <InputWithLabel
              label={CONTACT_US.phone.label}
              name='phone'
              error={!!errors.phone}
              errorMessage={errors?.phone?.message}
              className='block'
            >
              <div className='relative'>
                <Input
                  type='text'
                  placeholder={CONTACT_US.phone.placeholder}
                  className='w-full shadow-none'
                  name='phone'
                  error={!!errors.phone}
                  errorMessage={errors?.phone?.message}
                  register={register}
                  value={watch('phone')}
                />
              </div>
            </InputWithLabel>
            <InputWithLabel
              label={CONTACT_US.email.label}
              name='email'
              error={!!errors.email}
              errorMessage={errors?.email?.message}
              className='block'
            >
              <div className='relative'>
                <Input
                  type='text'
                  placeholder={CONTACT_US.email.placeholder}
                  className='w-full shadow-none'
                  name='email'
                  error={!!errors.email}
                  errorMessage={errors?.email?.message}
                  register={register}
                  value={watch('email')}
                />
              </div>
            </InputWithLabel>
          </div>
          <InputWithLabel
            label={CONTACT_US.query.label}
            name='query'
            error={!!errors.query}
            errorMessage={errors?.query?.message}
            className='block mt-5'
          >
            <div className='relative'>
              <Input
                type='text'
                placeholder={CONTACT_US.query.placeholder}
                className='w-full shadow-none'
                name='query'
                error={!!errors.query}
                errorMessage={errors?.query?.message}
                register={register}
                value={watch('query.')}
              />
            </div>
          </InputWithLabel>
          <div className='mt-8'>
            <ContactUsBtn
              type='submit'
              text={CONTACT_US.button.contactUs_button}
              loading={isSubmitting}
              formType={type}
              // data-category='send_lead'
              // data-action='Lead submission'
              // data-label='-'
            >
              <img src='icons/get-started-white.svg' />
            </ContactUsBtn>
          </div>
        </form>
      )}
      <div aria-live='assertive' className='flex pointer-events-none'>
        <div className='flex flex-col items-center justify-center w-full space-y-4'>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showNotification}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='w-full py-4 overflow-hidden pointer-events-auto sm:py-6'>
              {showNotificationError ? (
                <div className='w-full text-left'>
                  <p className='text-sm font-medium text-red-500'>{showNotificationError}</p>
                </div>
              ) : (
                <div className='flex flex-col items-center justify-center h-72'>
                  <div className='flex justify-center'>
                    <img src='/icons/tick.svg' className='w-16 h-16 lg:w-24 lg:h-24' />
                  </div>
                  <div className='col-span-1 col-start-2 mt-5 text-center'>
                    <h1 className='text-4xl font-bold leading-none opacity-100 text-primary'>
                      {THANK_YOU.header.thank_you}
                    </h1>
                    <p
                      className='mt-2 text-base font-medium leading-snug text-gray opacity-90'
                      data-testid='thank-you-message'
                    >
                      {THANK_YOU.help_text.request_submitted}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

ContactUsForm.propTypes = {
  initialValues: PropTypes.object.isRequired
};

export default ContactUsForm;
