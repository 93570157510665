import React from 'react';
import { LANDING_PAGE } from 'src/formMapper';

const BankPartnetships = () => {
  return (
    <div className='container relative px-4 py-0 lg:py-12 mx-auto'>
      <div className='relative grid items-center lg:grid-cols-12 gap-6 lg:gap-0 py-0 lg:py-4'>
        <div className='pl-4 pr-4 sm:pl-24 sm:pr-8 lg:col-span-5'>
          <h1 className='app-heading-big mb-3 leading-tight lg:leading-56 text-primary'>
            {LANDING_PAGE.header.bank_part_text}
            <br />
            {LANDING_PAGE.header.bank_part_rem_text}
          </h1>
          <p className='app-text-dm-small mt-3 leading-[21px] text-gray opacity-80'>
            {LANDING_PAGE.helpText.bankPartnership}
          </p>
        </div>
        <div className='md:col-span-7 md:pl-12'>
          <img src='/icons/partnership-illustration.svg' />
        </div>
      </div>
    </div>
  );
};

export default BankPartnetships;
