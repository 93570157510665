import React from 'react';
// Components
// Icons
import DottedSmallIcon from 'icons/dotted-small.svg';
import DottedBigIcon from 'icons/dotted-big.svg';
import { LANDING_PAGE } from 'src/formMapper';

const LoanOffers = () => {
  return (
    <div className='pt-8 pb-8 lg:pt-20' data-testid='loan-offers-section'>
      <div className='container px-4 mx-auto'>
        <div className='relative grid items-center grid-cols-1 py-4 md:grid-cols-12 gap-y-16 md:gap-8'>
          <div className='flex flex-col items-center justify-center col-span-6 gap-3 md:gap-6 md:pl-8 md:-mr-12'>
            <div className='flex items-center justify-center gap-3 md:gap-6'>
              <div className='bank-card large-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img src='/images/landing-page/hdfc-large.png' alt='' className='h-6 mx-auto lg:h-10' />
                  </div>
                  <div className='back-img'>
                    <img
                      src='/images/landing-page/central-bank-of-india-large.png'
                      alt=''
                      className='h-6 mx-auto lg:h-8'
                    />
                  </div>
                </div>
              </div>
              <div className='relative'>
                <div className='bank-card large-bank-card'>
                  <div className='bank-card-inner'>
                    <div className='front-img'>
                      <img src='/images/landing-page/pnb-large.png' alt='' className='h-6 mx-auto lg:h-10' />
                    </div>
                    <div className='back-img'>
                      <img src='/images/landing-page/iifl-large.png' alt='' className='h-6 mx-auto lg:h-10' />
                    </div>
                  </div>
                </div>
                <div className='absolute z-0 -right-4 -top-5'>
                  <DottedSmallIcon />
                </div>
              </div>
            </div>
            <div className='relative flex items-center justify-center gap-3 md:gap-6'>
              <div className='bank-card large-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img src='/images/landing-page/icici-large.png' alt='' className='h-6 mx-auto lg:h-8' />
                  </div>
                  <div className='back-img'>
                    <img src='/images/landing-page/lichfl-large.png' alt='' className='h-6 mx-auto lg:h-8' />
                  </div>
                </div>
              </div>
              <div className='bank-card small-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img src='/images/landing-page/bank-2-small.png' alt='' className='w-6 h-6 mx-auto lg:h-9 lg:w-9' />
                  </div>
                  <div className='back-img'>
                    <img src='/images/landing-page/bank-2-small.png' alt='' className='w-6 h-6 mx-auto lg:h-9 lg:w-9' />
                  </div>
                </div>
              </div>
              <div className='bank-card large-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img src='/images/landing-page/axis-large.png' alt='' className='h-6 mx-auto lg:h-10' />
                  </div>
                  <div className='back-img'>
                    <img src='/images/landing-page/lt-large.png' alt='' className='h-6 mx-auto lg:h-10' />
                  </div>
                </div>
              </div>
              <div className='absolute z-0 -left-8 -top-8'>
                <DottedSmallIcon />
              </div>
              <div className='absolute z-0 hidden right-16 top-6 md:block'>
                <DottedBigIcon />
              </div>
            </div>
            <div className='flex items-center justify-center gap-3 md:gap-6'>
              <div className='bank-card large-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img src='/images/landing-page/sbi-home-loan-large.png' alt='' className='h-6 mx-auto lg:h-6' />
                  </div>
                  <div className='back-img'>
                    <img src='/images/landing-page/pnb-large.png' alt='' className='h-6 mx-auto lg:h-10' />
                  </div>
                </div>
              </div>
              <div className='bank-card large-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img src='/images/landing-page/bajaj-large.png' alt='' className='h-6 mx-auto lg:h-8' />
                  </div>
                  <div className='back-img'>
                    <img src='/images/landing-page/hdfc-large.png' alt='' className='h-6 mx-auto lg:h-10' />
                  </div>
                </div>
              </div>
            </div>
            <div className='relative flex items-center justify-center gap-3 md:gap-6'>
              <div className='bank-card large-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img src='/images/landing-page/lichfl-large.png' alt='' className='h-6 mx-auto lg:h-9' />
                  </div>
                  <div className='back-img'>
                    <img src='/images/landing-page/axis-large.png' alt='' className='h-6 mx-auto lg:h-10' />
                  </div>
                </div>
              </div>
              <div className='bank-card large-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img src='/images/landing-page/lt-large.png' alt='' className='h-6 mx-auto lg:h-8' />
                  </div>
                  <div className='back-img'>
                    <img src='/images/landing-page/icici-large.png' alt='' className='h-6 mx-auto lg:h-8' />
                  </div>
                </div>
              </div>
              <div className='absolute z-0 -right-4 lg:-right-10 -bottom-10'>
                <DottedSmallIcon />
              </div>
              <div className='absolute z-0 -left-16 -top-12'>
                <DottedBigIcon />
              </div>
            </div>
            <div className='flex items-center justify-center gap-3 md:gap-6'>
              <div className='bank-card large-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img
                      src='/images/landing-page/central-bank-of-india-large.png'
                      alt=''
                      className='h-6 mx-auto lg:h-8'
                    />
                  </div>
                  <div className='back-img'>
                    <img src='/images/landing-page/bajaj-large.png' alt='' className='h-6 mx-auto lg:h-8' />
                  </div>
                </div>
              </div>
              <div className='bank-card large-bank-card'>
                <div className='bank-card-inner'>
                  <div className='front-img'>
                    <img src='/images/landing-page/iifl-large.png' alt='' className='h-6 mx-auto lg:h-8' />
                  </div>
                  <div className='back-img'>
                    <img src='/images/landing-page/sbi-home-loan-large.png' alt='' className='h-6 mx-auto lg:h-6' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='md:col-span-5 md:col-start-8'>
            <h1 className='mb-3 leading-10 app-heading-main text-primary'>
              {LANDING_PAGE.header.getway} <br /> {LANDING_PAGE.header.loanOffer}
            </h1>
            <p className='leading-snug app-text-dm-small text-gray opacity-80'>{LANDING_PAGE.helpText.loanOffer}</p>
            <div className='w-full my-3 border-t lg:my-6 border-black-100 opacity-10' />
            <div className='flex justify-between mt-4 space-x-4 lg:mt-0'>
              <div>
                <h4 className='text-2xl font-bold lg:mb-2 lg:text-5xl text-secondary font-body'>
                  {LANDING_PAGE.helpText.digit1}
                </h4>
                <p className='uppercase app-text-link text-primary'>{LANDING_PAGE.helpText.bankPartner}</p>
              </div>
              <div>
                <h4 className='text-2xl font-bold lg:mb-2 lg:text-5xl text-secondary font-body'>
                  {LANDING_PAGE.helpText.digit2}
                </h4>
                <p className='uppercase app-text-link text-primary'>{LANDING_PAGE.helpText.fasterApproval}</p>
              </div>
              <div>
                <h4 className='text-2xl font-bold lg:mb-2 lg:text-5xl text-secondary font-body'>
                  {LANDING_PAGE.helpText.digit3}
                </h4>
                <p className='uppercase app-text-link text-primary'>{LANDING_PAGE.helpText.loanApproval}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanOffers;
