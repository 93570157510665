const LOAN_TENURE_RANGE = [...Array(6).keys()];

export const LOAN_TYPES = [
  { name: 'New Home Loan', id: 1, src: '/images/loan.png' },
  { name: 'Transfer Home Loan', id: 2, src: '/images/transfer.png' },
  { name: 'Loan Against Property', id: 3, src: '/images/new.png' },
  { name: 'Plot Loan', id: 4, src: '/images/plotLoan.png' }
];

export const LOAN_TENURE = LOAN_TENURE_RANGE.map((key) => ({
  id: (key + 1) * 5,
  name: `${(key + 1) * 5}`
}));

export const EMPLOYER_TYPES = [
  { id: 1, name: 'Limited Company / LLP / Partnership / Government' },
  { id: 2, name: 'Proprietorship & Others' }
];

export const SENP_EMPLOYER_TYPES = [
  { id: 1, name: 'Private Limited Company' },
  { id: 2, name: 'LLP / Partnership' },
  { id: 4, name: 'Proprietorship & Others' }
];
