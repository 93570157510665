import React from 'react';
// Icons
import AboutUsIcon from 'icons/about-us.svg';
import { LANDING_PAGE } from 'src/formMapper';

const AboutUs = () => {
  return (
    <div className='container px-4 mx-auto'>
      <div className='grid items-center lg:grid-cols-12 lg:gap-8'>
        <div className='flex lg:col-span-7'>
          <img src='/icons/remaining-illustration.svg' />
        </div>
        <div className='lg:col-span-5 lg:pr-3'>
          <div className='flex items-center mb-3 space-x-2 uppercase text-primary leading-0'>
            <AboutUsIcon />
            <span className='app-text-link'>{LANDING_PAGE.header.about_us_text}</span>
          </div>
          <h1 className='app-heading-big mb-2 text-primary leading-[40px] md:leading-[56px]'>
            {LANDING_PAGE.header.about_us_header_text}
            <br /> {LANDING_PAGE.header.about_us_header_rem_text}
          </h1>
          <h2 className='app-heading-primary leading-tight text-secondary mt-1 mb-3'>
            {LANDING_PAGE.subHeader.about_us_subheader_text}
          </h2>
          <h2 className='app-text-dm-small mt-1 leading-6 opacity-80 text-gray'>{LANDING_PAGE.helpText.aboutUs}</h2>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
