import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { amountToThousandSeparator, isEmpty } from 'src/helper';
import useInteractiveStates from 'src/hooks/interactiveStates';

const InputRange = ({ control, name, placeholder, inWords, min, max, step, isEnabled }) => {
  const [minVal, setMinVal] = useState(min);
  const range = useRef(null);

  const [data, setData] = useState(0);
  const { lostFocus, onBlur, onFocus } = useInteractiveStates();
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: ''
  });

  const updateValue = useCallback((val) => {
    setData(val);
  }, []);

  const getPercent = useCallback((value) => Math.round((value * 100) / max, [value, max]));

  useEffect(() => {
    const minPercent = getPercent(value);

    if (range.current) {
      range.current.style.left = 0;
      range.current.style.width = `${minPercent}%`;
    }
  }, [getPercent, minVal]);

  return (
    <div className='relative rounded-md' role='presentation'>
      <div className='relative'>
        <div>
          <input
            type='text'
            className={`block w-full opacity-90 ${
              error && lostFocus ? 'border border-red text-red' : (value && lostFocus && 'border') || ''
            }`}
            value={amountToThousandSeparator(value || data)}
            placeholder={placeholder}
            onChange={(e) => {
              const {
                target: { value }
              } = e;
              if (Number(value.replace(/, /g, '')) > max) return;
              const toNumber = Number(value.replace(/, /g, ''));
              if (Number.isNaN(toNumber)) return;
              updateValue(toNumber);
              onChange(toNumber);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={isEnabled}
            data-testid={`${name}_input`}
          />
        </div>
        <div className='absolute bottom-[1px] w-full'>
          <div className='range-wrapper'>
            <input
              type='range'
              min={min}
              max={max}
              step={step}
              value={value || data}
              onChange={(e) => {
                const val = e.target.value;
                setMinVal(val);
                setData(val);
                onChange(val);
              }}
              className='range-thumb range-thumb--left'
              style={{ zIndex: 5 }}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={isEnabled}
              data-testid={`${name}_slider`}
            />
            <div className='range-slider'>
              <div className='range-slider__track' />
              <div ref={range} className='range-slider__range' />
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(inWords) && !lostFocus && (
        <p className='mt-[5px] app-text-dm-xxs text-gray text-opacity-60 absolute right-0 truncate w-full text-right'>
          {inWords}
        </p>
      )}
    </div>
  );
};

InputRange.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  inWords: PropTypes.string,
  isEnabled: PropTypes.bool
};

InputRange.defaultProps = {
  placeholder: '',
  min: 0,
  max: 1000,
  step: 10,
  inWords: '',
  isEnabled: false
};

export default InputRange;
