import * as yup from 'yup';
import { VALIDATION_MESSAGE } from 'src/formMapper';

export const validationSchema = yup
  .object()
  .shape({
    name: yup.string().required(VALIDATION_MESSAGE.name),
    phone: yup.string().required(VALIDATION_MESSAGE.mobileNum),
    email: yup.string().required(VALIDATION_MESSAGE.mailId).email(VALIDATION_MESSAGE.validmailId),
    query: yup.string().required(VALIDATION_MESSAGE.query)
  })
  .defined();
